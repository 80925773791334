/* You can add global styles to this file, and also import other style files */

/*Import theme style*/
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
/*@import url('https://fonts.googleapis.com/css?family=Lato|Roboto+Condensed|Ubuntu');*/
@import "./assets/styles/normalize.css";
@import "./assets/styles/material-icons.css";
@import "./assets/styles/fonts.css";

/*font-family: 'Lato', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
font-family: 'Ubuntu', sans-serif;*/
html {
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  background-color: #eee;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 1.2;
  color: rgba(0, 0, 0, 0.9);
  letter-spacing: 0.05em;
}

.card__wrapper {
  padding: 20px;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(138, 138, 138, 0.15);
  overflow-y: auto;
}

#fileManager {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

app-progress-bar,
app-dashboard,
app-station,
app-operation,
app-calibration {
  width: 100%;
}
app-camera-dialog {
  width: 100%;
  height: 100%;
}

app-progress-bar {
  overflow: hidden;
  display: block;
}

.jorney__field .mat-form-field-infix {
  width: 45%;
}

#modal {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 60, 70, 0.5);
  justify-content: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  z-index: 1;
}

#modal .modal__container {
  background-color: white;
  width: 450px;
  min-height: 200px;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 1px 6px #7d7d7d;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
}

#modal .modal__container p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 50px;
}

#modal .modal__button__container {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#modal .modal__button__container .modal__button {
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  padding: 8px 10px;
  margin: 0 15px;
  color: white;
  background-color: #3f51b5;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
}

.app__container {
  padding: 0 25px;
  width: 100%;
  height: calc(95vh - 150px);
  box-sizing: border-box;
}
.app__container.station__container {
  height: calc(95vh - 60px);
}
.btn--skip {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  margin-right: 20px;
  color: #2196f3;
}
.btn--save {
  background-color: #8bc34a;
  color: #074563;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .app__container {
    padding: 0 10px;
  }
  .select__field .mat-form-field-infix {
    width: 130px;
  }
}
